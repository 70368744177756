import {schema} from '~hooks/useForm'

export const loginSchema = schema.object({
	email: schema.string().email().nonempty(),
	password: schema.string().nonempty(),
})

export const registerSchema = schema.object({
	display_name: schema.string().nonempty(),
	locale: schema.string().nonempty(),
	email: schema.string().email().nonempty(),
	password: schema.string().nonempty(),
	name: schema.string().nonempty(),
})

export const resetPasswordSchema = schema.object({
	email: schema.string().email().nonempty(),
})
