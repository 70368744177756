import {useAtomValue} from 'jotai'
import {AppState} from '~states/App'
import {LocaleState} from '~states/Locale'

import {resolver, schema, useForm} from '~hooks/useForm'

import {resetPasswordSchema} from '~schemas/auth'

import {Button, Input, Field} from '~ui/Forms'
import {Icon} from '~ui/Modules'

interface FormProps {
	setForm: (form: string) => void
	onSubmit: (data: FormData) => Promise<void>
	className?: string
}

type FormData = schema.infer<typeof resetPasswordSchema>

const ResetPasswordForm = ({setForm, onSubmit, className = '', ...props}: FormProps) => {
	const locale = useAtomValue(LocaleState)
	const app = useAtomValue(AppState)

	const {
		register: formRegister,
		handleSubmit: formHandleSubmit,
		formState: {errors: formErrors},
	} = useForm<FormData>({
		resolver: resolver(resetPasswordSchema),
	})

	return (
		<div className={'tw-flex tw-w-[370px] tw-flex-col tw-gap-y-[20px]' + (className && ' ' + className)} {...props}>
			<div className="tw-flex tw-justify-center">
				<Icon className="tw-h-[48px] tw-w-[48px] tw-flex-shrink-0 tw-text-logo" name="#icon-app-logo" />
			</div>
			<form onSubmit={formHandleSubmit(onSubmit)} className="tw-flex tw-flex-col tw-gap-y-[40px]">
				<h1 className="tw-text-center tw-text-[36px] tw-font-normal">{locale['INDEX']['FORMS']['RESET_PASSWORD']['TITLE']}</h1>
				<div className="tw-flex tw-flex-col tw-gap-y-[20px]">
					<Field
						name="email"
						description={locale['INDEX']['FORMS']['RESET_PASSWORD']['FIELDS']['EMAIL']['DESCRIPTION']}
						error={formErrors?.['email']}
						label={locale['INDEX']['FORMS']['RESET_PASSWORD']['FIELDS']['EMAIL']['LABEL']}
						className="ui-field-primary"
					>
						<Input
							type="email"
							className="ui-input-primary tw-max-w-full"
							placeholder={locale['INDEX']['FORMS']['RESET_PASSWORD']['FIELDS']['EMAIL']['PLACEHOLDER']}
							{...formRegister('email')}
						/>
					</Field>
					<Button type="submit" className="ui-button-primary tw-w-full" loading={app['loading']}>
						{locale['INDEX']['FORMS']['RESET_PASSWORD']['SUBMIT']}
					</Button>
				</div>
				<div className="tw-text-center tw-leading-[20px]">
					{locale['INDEX']['FORMS']['RESET_PASSWORD']['SIGNIN_TITLE']}
					<br />
					<button type="button" onClick={() => setForm('login')} className="ui-link-primary tw-font-medium">
						{locale['INDEX']['FORMS']['RESET_PASSWORD']['SIGNIN']}
					</button>
				</div>
			</form>
		</div>
	)
}

export default ResetPasswordForm
