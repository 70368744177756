import {forwardRef, useState} from 'react'

import {Input, InputInterface} from '~ui/Forms'
import {Icon} from '~ui/Modules'

interface Props extends InputInterface {
	className?: string
	iconShow?: string
	iconHide?: string
}

const PasswordInput = forwardRef<HTMLInputElement, Props>(({type = 'password', className = '', children = null, iconHide = '#icon-eye-slash', iconShow = '#icon-eye', ...props}, ref) => {
	const [showed, setShowed] = useState(false)

	return (
		<Input type={showed ? 'text' : type} className={'ui-input-password' + (className && ' ' + className)} ref={ref} {...props}>
			{children}

			<button className="ui-input-password-toggle" onClick={() => setShowed((showed) => !showed)} type="button">
				<Icon className="ui-input-password-toggle-icon" name={showed ? iconHide : iconShow} />
			</button>
		</Input>
	)
})

export {PasswordInput}
