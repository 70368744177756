import {ChangeEvent, CSSProperties, forwardRef, Fragment, useEffect, useState} from 'react'

import {Listbox} from '@headlessui/react'
import {Placement, size} from '~hooks/useFloat'

import {useDebounce} from '~hooks/useDebounce'

import {Input} from '~ui/Forms'
import {Icon, Float, IconInterface} from '~ui/Modules'

export interface SelectOption {
	style?: CSSProperties
	icon?: string | IconInterface
	key: string
	value: number | string
}

interface SelectInterface {
	allowEmpty?: boolean
	className?: string
	defaultValue?: number | string | (number | string)[]
	icon?: string
	iconArrow?: string
	iconRemove?: string
	iconSearch?: string
	minSearchQuery?: number
	placement?: Placement
	multiple?: boolean
	options: SelectOption[]
	placeholder?: string
	removeItemButton?: boolean
	search?: boolean
	searchEmpty?: string
	searchPlaceholder?: string
	selectedOptionsSeparator?: string
	setValue?: (value: number | string | (number | string)[] | ChangeEvent<Element>) => void
	value?: number | string | (number | string)[]
	disabled?: boolean
	readOnly?: boolean
	showedOptions?: number
}

//TODO: add readonly logic

const Select = forwardRef<HTMLDivElement, SelectInterface>(
	(
		{
			allowEmpty = false,
			className = '',
			defaultValue = '',
			icon = '',
			iconArrow = '#icon-angle-down',
			iconRemove = '#icon-xmark',
			iconSearch = '#icon-magnifying-glass',
			minSearchQuery = 3,
			multiple = false,
			options = [],
			placeholder = '',
			removeItemButton = true,
			placement = 'bottom-start',
			search = false,
			searchEmpty = '',
			searchPlaceholder = '',
			selectedOptionsSeparator = '',
			setValue = null,
			value = null,
			disabled = false,
			readOnly = false,
			showedOptions = null,
			...props
		},
		ref
	) => {
		const getOption = (newValue, isAllowEmpty) => {
			if (!Array.isArray(newValue)) {
				newValue = [newValue]
			}

			let newOptions = []

			if (options.length > 0) {
				newOptions = options.filter((option) => newValue.includes(option['value']))

				if (newOptions.length === 0 && !isAllowEmpty) {
					newOptions = [options?.[0]]
				}

				if (newOptions.length === 0 && isAllowEmpty) {
					newOptions = []
				}
			}

			return newOptions
		}

		const [filteredOptions, setFilteredOptions] = useState(options)
		const [selectedOptions, setSelectedOptions] = useState(getOption(value ?? defaultValue, allowEmpty))

		const [hiddenSelectedOptions, setHiddenSelectedOptions] = useState(selectedOptions.length > showedOptions ? selectedOptions.slice(0, selectedOptions.length - showedOptions) : [])
		const [showedSelectedOptions, setShowedSelectedOptions] = useState(selectedOptions.length > showedOptions ? selectedOptions.slice(-showedOptions) : selectedOptions)

		const [query, setQuery] = useState('')

		const searchQuery = useDebounce(query, 150)

		const onChangeHandler = (value) => {
			const options = getOption(value, allowEmpty)

			setSelectedOptions(options)
		}

		const handleRemove = (value) => {
			const options = selectedOptions.filter((option) => option['value'] !== value)

			setSelectedOptions(options)
		}

		useEffect(() => {
			if (typeof selectedOptions !== 'undefined' && setValue) {
				setValue(multiple ? selectedOptions.map((option) => option?.['value']) : selectedOptions[0]?.['value'])
			}
		}, [multiple, setValue, selectedOptions])

		useEffect(() => {
			setHiddenSelectedOptions(selectedOptions.length > showedOptions ? selectedOptions.slice(0, selectedOptions.length - showedOptions) : [])
			setShowedSelectedOptions(selectedOptions.length > showedOptions ? selectedOptions.slice(-showedOptions) : selectedOptions)
		}, [selectedOptions, showedOptions, setHiddenSelectedOptions, setShowedSelectedOptions])

		//TODO: добавить подсветку
		useEffect(() => {
			if (search && searchQuery?.length >= minSearchQuery) {
				const filtered = options.filter((option) => (option?.['key'] ? option['key'].toLowerCase().trim().includes(searchQuery.toLowerCase().trim()) : false))

				setFilteredOptions(filtered)
			} else {
				setFilteredOptions(options)
			}
		}, [minSearchQuery, options, search, searchQuery])

		return (
			<Listbox
				as="div"
				className={'ui-select' + (multiple ? ' ui-select-multiple' : '') + (className && ' ' + className) + (disabled ? ' ui-select-disabled' : '') + (readOnly ? ' ui-select-read-only' : '')}
				onChange={onChangeHandler}
				value={multiple ? selectedOptions.map((option) => option?.['value']) : selectedOptions[0]?.['value'] ?? null}
				multiple={multiple}
				disabled={disabled}
				ref={ref}
				{...props}
			>
				{({open}) => (
					<Float
						placement={placement}
						enter="tw-transition tw-ease-out tw-duration-100"
						offset={{mainAxis: 8}}
						middleware={() => [
							size({
								apply({availableWidth, availableHeight, elements}) {
									Object.assign(elements.floating.style, {
										minWidth: (elements.reference as HTMLElement).offsetWidth + 'px',
										maxWidth: availableWidth + 'px',
										maxHeight: availableHeight + 'px',
									})

									if (elements.floating.childNodes.length > 0) {
										;(elements.floating.childNodes[0] as HTMLElement).style.maxHeight = availableHeight + 'px'
									}
								},
							}),
						]}
						composable
					>
						<Float.Reference>
							<Listbox.Button className={'ui-select-button' + (open ? ' ui-select-button-opened' : '')}>
								{icon.length > 0 && <Icon className="ui-select-button-icon" name={icon} />}

								<div className="ui-select-button-options">
									{showedSelectedOptions.map((option, index) => (
										<Fragment key={option['key'] + option['value']}>
											<div className="ui-select-button-option">
												{option?.['icon'] && (
													<>
														{typeof option['icon'] === 'string' && <Icon name={option['icon']} className="ui-select-button-option-icon" />}
														{typeof option['icon'] === 'object' && option['icon']?.['name'] && (
															<Icon
																name={option['icon']['name']}
																className={'ui-select-button-option-icon' + (option['icon']?.['className'] ? ' ' + option['icon']['className'] : '')}
																style={option['icon']['style']}
															/>
														)}
													</>
												)}
												{option?.['key']}
												{removeItemButton && iconRemove && (allowEmpty || (!allowEmpty && selectedOptions.length !== 1)) && (
													<Icon
														name={iconRemove}
														className="ui-select-button-option-remove"
														onClick={(event) => {
															event.preventDefault()
															event.stopPropagation()
															handleRemove(option['value'])
														}}
													/>
												)}
											</div>

											{index !== selectedOptions.length - 1 && selectedOptionsSeparator && <div className="ui-select-button-option-separator">{selectedOptionsSeparator}</div>}
										</Fragment>
									))}
									{showedOptions && hiddenSelectedOptions.length > 0 && (
										<div
											onClick={(e) => {
												e.preventDefault()
												e.stopPropagation()
											}}
											className="ui-select-options-more"
										>
											+{hiddenSelectedOptions.length}
										</div>
									)}
									{selectedOptions.length === 0 && placeholder && <span className="ui-select-button-placeholder">{placeholder}</span>}
								</div>

								<Icon className="ui-select-button-arrow" name={iconArrow} />
							</Listbox.Button>
						</Float.Reference>

						<Float.Content>
							{options.length > 0 && (
								<Listbox.Options as="div" className="ui-select-options">
									{search && (
										<Input
											autoComplete="none"
											className="ui-select-options-search"
											icon={iconSearch}
											onChange={(event) => setQuery(event.target.value)}
											placeholder={searchPlaceholder}
											value={query}
										/>
									)}

									{filteredOptions.length === 0 && search && searchEmpty && <div className="ui-select-options-search-empty">{searchEmpty}</div>}

									{filteredOptions.length > 0 && (
										<ul className="ui-select-options-list">
											{filteredOptions.map((option) => (
												<Listbox.Option as={Fragment} key={option['key'] + option['value']} value={option['value']}>
													{/*TODO: баг с подсветкой активного пункта, когда селект вне формы. Возможно, переделать с selected на проверку в массиве */}
													{({selected}) => (
														<li
															className={'ui-select-option' + (selected ? ' ui-select-option-active' : '') + (option?.['className'] ? ' ' + option['className'] : '')}
															style={option?.['style']}
														>
															{option?.['icon'] && (
																<>
																	{typeof option['icon'] === 'string' && <Icon name={option['icon']} />}
																	{typeof option['icon'] === 'object' && option['icon']?.['name'] && (
																		<Icon name={option['icon']['name']} className={option['icon']['className']} style={option['icon']['style']} />
																	)}
																</>
															)}

															<div className="ui-select-option-text">{option['key']}</div>
														</li>
													)}
												</Listbox.Option>
											))}
										</ul>
									)}
								</Listbox.Options>
							)}
						</Float.Content>
					</Float>
				)}
			</Listbox>
		)
	}
)

export {Select}
export type {SelectInterface}
