import {memo, ReactNode} from 'react'

import {Markup, Node} from 'interweave'
import {polyfill} from 'interweave-ssr'

import {Link} from '~ui/Modules'

polyfill()

interface Props {
	children: ReactNode
	as?: string
	transform?: string[]
	className?: string
}

const Html = memo(({children, as = 'div', transform = ['a'], className, ...props}: Props) => {
	const onTransform = (node: HTMLElement, children: Node[]): ReactNode => {
		if (!transform.includes(node.tagName)) {
			return undefined
		} else {
			const attributes = {}

			//проверить, будет ли нормально работать style
			Array.from(node.attributes).map((item) => {
				attributes[item['name']] = item['value']
			})

			//TODO: временный костыль:
			const replace = [
				['classname', 'className']
			]
			
			replace.map((item) => {
				attributes[item[1]] = attributes[item[0]]
			
				delete attributes[item[0]]
			})

			switch (node.tagName) {
				case 'a':
					return <Link {...attributes}>{children as ReactNode}</Link>

				default:
					return undefined
			}
		}
	}

	return <Markup tagName={as} noWrap={as.length > 0 ? false : true} content={children as string} transform={onTransform} {...props} className={className && ' ' + className} />
})

export {Html}
