import {Fragment, forwardRef, LegacyRef, ReactElement, ElementType} from 'react'

import {Float as FloatContainer, FloatProps, FloatReferenceRenderProp} from '@headlessui-float/react'
import {OffsetOptions, Placement} from '~hooks/useFloat'

//TODO: поработать над свойством floatingAs. В идеале - пробрасывать в него fragment, чтобы по умолчанию список не рендерился, но тогда он ругается в консоли

//Т.к. содержимое меню и тд не рендерится по умолчанию, появляется скачок позиционирования - исправвить

const Wrapper = forwardRef((props, ref: LegacyRef<HTMLDivElement>) => <div className="ui-float" ref={ref} {...props} />)

interface Props extends FloatProps {
	children: ReactElement[] | ((slot: FloatReferenceRenderProp) => ReactElement[])
	as?: ElementType<any>
	floatingAs?: ElementType<any>
	placement?: Placement
	strategy?: any
	enter?: string
	enterFrom?: string
	enterTo?: string
	leave?: string
	leaveFrom?: string
	leaveTo?: string
	zIndex?: string | number
	transform?: boolean
	flip?: number | boolean | Partial<any>
	offset?: OffsetOptions
	shift?: number | boolean
	className?: string
	middleware?: any
}

const Float = ({
	children,
	as = Fragment,
	floatingAs = Fragment,
	placement = 'bottom',
	strategy = 'absolute',
	offset = {},
	enter = 'tw-transition tw-ease-out tw-duration-200',
	enterFrom = 'tw-opacity-0',
	enterTo = 'tw-opacity-100',
	leave = 'tw-transition tw-ease-in tw-duration-150',
	leaveFrom = 'tw-opacity-100',
	leaveTo = 'tw-opacity-0',
	zIndex = '50',
	transform = true,
	flip = true,
	shift = 16,
	className = '',
	...props
}: Props) => {
	return (
		<FloatContainer
			className={className ? className : ''}
			as={as ? as : Fragment}
			floatingAs={Wrapper}
			placement={placement}
			offset={offset}
			strategy={strategy}
			enter={enter}
			enterFrom={enterFrom}
			enterTo={enterTo}
			leave={leave}
			leaveFrom={leaveFrom}
			leaveTo={leaveTo}
			zIndex={zIndex}
			transform={transform}
			flip={flip}
			shift={shift}
			{...props}
		>
			{children}
		</FloatContainer>
	)
}

const Reference = ({children}) => {
	return <FloatContainer.Reference>{children}</FloatContainer.Reference>
}
Float.Reference = Reference

const Content = ({children}) => {
	return <FloatContainer.Content>{children}</FloatContainer.Content>
}
Float.Content = Content

export {Float}
