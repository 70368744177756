import {useAtomValue, useSetAtom} from 'jotai'
import {AppState} from '~states/App'
import {LocaleState} from '~states/Locale'
import {LanguageState} from '~states/Language'
import {ModalState} from '~states/Modal'

import {Controller, resolver, schema, useForm} from '~hooks/useForm'
import {AppLanguages} from '~helpers/constants'
import {localeReplace} from '~helpers/functions'

import {registerSchema} from '~schemas/auth'

import {Button, Input, PasswordInput, Field, Select} from '~ui/Forms'
import {Html, Icon} from '~ui/Modules'

interface FormProps {
	setForm: (form: string) => void
	onSubmit: (data: FormData) => Promise<void>
	className?: string
}

type FormData = schema.infer<typeof registerSchema>

const RegisterForm = ({setForm, onSubmit, className = '', ...props}: FormProps) => {
	const locale = useAtomValue(LocaleState)
	const language = useAtomValue(LanguageState)
	const app = useAtomValue(AppState)
	const setModal = useSetAtom(ModalState)

	const {
		register: formRegister,
		control: formControl,
		handleSubmit: formHandleSubmit,
		formState: {errors: formErrors},
	} = useForm<FormData>({
		resolver: resolver(registerSchema),
	})

	return (
		<div className={'tw-flex tw-w-[370px] tw-flex-col tw-gap-y-[20px]' + (className && ' ' + className)} {...props}>
			<div className="tw-flex tw-justify-center">
				<Icon className="tw-h-[48px] tw-w-[48px] tw-flex-shrink-0 tw-text-logo" name="#icon-app-logo" />
			</div>
			<form onSubmit={formHandleSubmit(onSubmit)} className="tw-flex tw-flex-col tw-gap-y-[40px]">
				<h1 className="tw-text-center tw-text-[36px] tw-font-normal">{locale['INDEX']['FORMS']['REGISTER']['TITLE']}</h1>

				<div className="tw-flex tw-flex-col tw-gap-y-[20px]">
					<Field name="name" error={formErrors?.['name']} label={locale['INDEX']['FORMS']['REGISTER']['FIELDS']['NAME']['LABEL']} className="ui-field-primary">
						<Input className="ui-input-primary tw-max-w-full" placeholder={locale['INDEX']['FORMS']['REGISTER']['FIELDS']['NAME']['PLACEHOLDER']} {...formRegister('name')} />
					</Field>
					<Field name="display_name" error={formErrors?.['display_name']} label={locale['INDEX']['FORMS']['REGISTER']['FIELDS']['DISPLAY_NAME']['LABEL']} className="ui-field-primary">
						<Input
							className="ui-input-primary tw-max-w-full"
							placeholder={locale['INDEX']['FORMS']['REGISTER']['FIELDS']['DISPLAY_NAME']['PLACEHOLDER']}
							{...formRegister('display_name')}
						/>
					</Field>
					<Field name="locale" error={formErrors?.['locale']} label={locale['INDEX']['FORMS']['REGISTER']['FIELDS']['LOCALE']['LABEL']} className="ui-field-primary">
						<Controller
							control={formControl}
							name="locale"
							render={({field: {value, onChange}}) => (
								<Select
									className="ui-select-primary tw-w-full"
									value={value}
									defaultValue={language}
									setValue={onChange}
									options={Object.entries(AppLanguages).map(([key, item]) => ({key: item['name'], value: key}))}
									placeholder={locale['INDEX']['FORMS']['REGISTER']['FIELDS']['LOCALE']['PLACEHOLDER']}
								/>
							)}
						/>
					</Field>
					<Field name="email" error={formErrors?.['email']} label={locale['INDEX']['FORMS']['REGISTER']['FIELDS']['EMAIL']['LABEL']} className="ui-field-primary">
						<Input
							type="email"
							className="ui-input-primary tw-max-w-full"
							placeholder={locale['INDEX']['FORMS']['REGISTER']['FIELDS']['EMAIL']['PLACEHOLDER']}
							{...formRegister('email')}
						/>
					</Field>
					<Field name="password" error={formErrors?.['password']} label={locale['INDEX']['FORMS']['REGISTER']['FIELDS']['PASSWORD']['LABEL']} className="ui-field-primary">
						<PasswordInput
							type="password"
							className="ui-input-primary tw-max-w-full"
							placeholder={locale['INDEX']['FORMS']['REGISTER']['FIELDS']['PASSWORD']['PLACEHOLDER']}
							{...formRegister('password')}
						/>
					</Field>

					{/* TODO: add separated files? */}
					<Html transform={[]} className="tw-leading-[20px]">
						{localeReplace(
							locale['INDEX']['FORMS']['REGISTER']['PRIVACY_POLICY'],
							['link-1', 'link-2'],
							[
								'<a href="/assets/files/privacy-policy-' + language + '.pdf" target="_blank" rel="noopener noreferrer" class="ui-link-primary tw-font-medium">' + locale['INDEX']['FORMS']['REGISTER']['PRIVACY_POLICY_LINK_1'] + '</a>',
								'<a href="/assets/files/privacy-policy-' + language + '.pdf" target="_blank" rel="noopener noreferrer" class="ui-link-primary tw-font-medium">' + locale['INDEX']['FORMS']['REGISTER']['PRIVACY_POLICY_LINK_2'] + '</a>',
							]
						)}
					</Html>

					<Button type="submit" className="ui-button-primary tw-w-full" loading={app['loading']}>
						{locale['INDEX']['FORMS']['REGISTER']['SUBMIT']}
					</Button>
				</div>
				<div className="tw-text-center tw-leading-[20px]">
					{locale['INDEX']['FORMS']['REGISTER']['SIGNIN_TITLE']}
					<br />
					<button type="button" onClick={() => setForm('login')} className="ui-link-primary tw-font-medium">
						{locale['INDEX']['FORMS']['REGISTER']['SIGNIN']}
					</button>
				</div>
			</form>
		</div>
	)
}

export default RegisterForm
