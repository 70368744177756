import Head from 'next/head'
import {useRouter} from 'next/router'
import {useState, useEffect} from 'react'

import {login, register, resetPassword} from '~actions/auth'

import {useAtomValue, useSetAtom} from 'jotai'
import {AppState} from '~states/App'
import {LocaleState} from '~states/Locale'
import {UserState} from '~states/User'

import {amplitudeSetUserProperties, amplitudeTrackEvent} from '~hooks/useAmplitude'
import {schema} from '~hooks/useForm'

import {loginSchema} from '~schemas/auth'

import {Notification} from '~ui/Modules'

import LoginForm from '~chunks/_app/form/Login'
import RegisterForm from '~chunks/_app/form/Register'
import ResetPasswordForm from '~chunks/_app/form/ResetPassword'

type FormData = schema.infer<typeof loginSchema>

interface Props {
	formInitial?: string
}

export async function getServerSideProps(context) {
	const data = {}

	data['formInitial'] = context['query']?.['form'] || 'login'

	return {
		props: data,
	}
}

function IndexPage({formInitial = ''}: Props) {
	const locale = useAtomValue(LocaleState)
	const setUser = useSetAtom(UserState)
	const setApp = useSetAtom(AppState)

	const [form, setForm] = useState(formInitial)

	const router = useRouter()

	useEffect(() => {
		setForm(formInitial)
	}, [formInitial])

	const loginHandler = async (data: FormData) => {
		setApp((app) => ({
			...app,
			loading: true,
		}))

		let response = {}

		response = await login(data)

		if (response['success']) {
			amplitudeTrackEvent('LOGGED_IN_SUCCESS')
			amplitudeSetUserProperties(null, response['body']['user']['email'])

			setUser((user) => ({
				...user,
				profile: response['body']['user'],
			}))

			await router.push(router['asPath'].split('?')[0] == '/' ? '/statistics' : router['asPath'])
		} else {
			amplitudeTrackEvent('LOGGED_IN_FAIL', {
				error_type: response['body']['status'],
			})

			switch (response['status']) {
				case 401:
					Notification.error(locale['INDEX']['FORMS']['LOGIN']['NOTIFICATIONS']['NOT_CORRECT'])

					break

				default:
					Notification.error(locale['INDEX']['FORMS']['LOGIN']['NOTIFICATIONS']['DEFAULT_FAILED'])
			}
		}

		setApp((app) => ({
			...app,
			loading: false,
		}))
	}

	const registerHandler = async (data: FormData) => {
		setApp((app) => ({
			...app,
			loading: true,
		}))

		let response = {}

		response = await register(data)

		if (response['success']) {
			amplitudeTrackEvent('LOGGED_IN_SUCCESS')
			amplitudeSetUserProperties(null, response['body']['user']['email'])

			setUser((user) => ({
				...user,
				profile: response['body']['user'],
			}))

			Notification.success(locale['INDEX']['FORMS']['REGISTER']['NOTIFICATIONS']['SUCCESS'])

			await router.push(router['asPath'].split('?')[0] == '/' ? '/statistics' : router['asPath'])
		} else {
			amplitudeTrackEvent('LOGGED_IN_FAIL', {
				error_type: response['body']['status'],
			})

			switch (response['status']) {
				case 401:
					Notification.error(locale['INDEX']['FORMS']['REGISTER']['NOTIFICATIONS']['NOT_CORRECT'])

					break

				case 422:
					Notification.error(locale['INDEX']['FORMS']['REGISTER']['NOTIFICATIONS']['NOT_UNIQUE'])

					break

				default:
					Notification.error(locale['INDEX']['FORMS']['REGISTER']['NOTIFICATIONS']['DEFAULT_FAILED'])
			}
		}

		setApp((app) => ({
			...app,
			loading: false,
		}))
	}

	const resetPasswordHandler = async (data: FormData) => {
		setApp((app) => ({
			...app,
			loading: true,
		}))

		let response = {}

		response = await resetPassword(data)

		if (response['success']) {
			amplitudeTrackEvent('RESET_EMAIL_PASSWORD_SEND')

			//! Добавление нотификации
			Notification.success(locale['INDEX']['FORMS']['RESET_PASSWORD']['NOTIFICATIONS']['SUCCESS'])

			setForm('login')
		} else {
			amplitudeTrackEvent('RESET_EMAIL_PASSWORD_FAIL', {
				error_type: response['body']['status'],
			})

			switch (response['status']) {
				case 404:
					Notification.error(locale['INDEX']['FORMS']['RESET_PASSWORD']['NOTIFICATIONS']['NOT_FOUND'])

					break

				case 429:
					Notification.error(locale['INDEX']['FORMS']['RESET_PASSWORD']['NOTIFICATIONS']['ALREADY_SENT'])

					break

				default:
					Notification.error(locale['INDEX']['FORMS']['RESET_PASSWORD']['NOTIFICATIONS']['DEFAULT_FAILED'])
			}
		}

		setApp((app) => ({
			...app,
			loading: false,
		}))
	}

	const formSwitchHandler = async (form: string) => {
		//Т.к. мы не хотим триггерить полоску загрузки при смене формы, то изменения квери сеттим без некст-роутера:
		//TODO: rework, then rework progressbar + next.router

		if (typeof window !== 'undefined') {
			const window_query = new URLSearchParams(window.location.search)

			if (form == 'login') {
				window_query.delete('form')
			} else {
				window_query.set('form', form)
			}

			window.history.replaceState({}, '', window.location.pathname + (window_query.toString() ? '?' + window_query.toString() : '') + window.location.hash)
		}

		setForm(form)
	}

	return (
		<>
			<Head>
				<title>{locale['INDEX']['TITLE'] + ' :: ' + process.env.NEXT_PUBLIC_APP_NAME}</title>
				<meta property="og:title" content={process.env.NEXT_PUBLIC_APP_NAME} />
			</Head>

			<div className="ui-form-layout">
				{form == 'login' && <LoginForm setForm={formSwitchHandler} onSubmit={loginHandler} />}

				{form == 'register' && <RegisterForm setForm={formSwitchHandler} onSubmit={registerHandler} />}

				{form == 'reset-password' && <ResetPasswordForm setForm={formSwitchHandler} onSubmit={resetPasswordHandler} />}
			</div>
		</>
	)
}
export default IndexPage
